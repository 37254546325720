<template>
  <b-modal
    id="exclude-leads-modal"
    ref="exclude-leads-modal"
    title="Exclude Leads"
    size="xl"
    centered
    hide-footer
    :hide-header-close="isLoading"
    no-close-on-backdrop
    @show="onShow"
  >
    <b-table
      show-empty
      sticky-header
      responsive
      :items="items"
      :fields="tableConfig.fields"
      :per-page="tableConfig.perPage"
      :current-page="tableConfig.currentPage"
      :sort-by="tableConfig.sortBy"
      :sort-direction="tableConfig.sortDirection"
      no-sort-reset
      no-local-sorting
    >
      <template #empty>
        <div class="text-center">
          Please select the leads you want to exclude
        </div>
      </template>
      <template #cell(message)="{ item }">
        <b-spinner
          v-if="item.isLoading"
          variant="primary"
          small
        />
        <template v-else>
          {{ item.message }}
        </template>
      </template>
      <template #cell(status)="{ item }">
        <b-spinner
          v-if="item.isLoading"
          variant="primary"
          small
        />
        <feather-icon
          v-if="item.message && !item.isLoading"
          :icon="item.status ? 'CheckIcon' : 'XIcon'"
          size="24"
          :class="{ 'check-icon': item.status, 'x-icon': !item.status }"
        />
      </template>
      <template #cell(currentReason)="{ item }">
        <HeroVueSelect
          v-if="!item.excludedReason"
          :id="`reason-${item.leadId}`"
          v-model="item.currentReason"
          :options="reasonOptions"
          class="mb-0"
          @open="setPopper(`reason-${item.leadId}`)"
        />
        <template v-else>
          {{ item.excludedReason }}
        </template>
      </template>
      <template #cell(action)="{ item }">
        <HeroButtonAction
          type="button"
          variant="primary"
          :disabled="item.isLoading || (!item.currentReason && !item.excludedReason)"
          @click="updateReason(item)"
        >
          {{ item.excludedReason ? 'Unassign' : 'Assign' }}
        </HeroButtonAction>
      </template>
    </b-table>
    <b-row class="mx-0 mb-1">
      <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
        <HeroTablePagination
          v-model="tableConfig.currentPage"
          :per-page="tableConfig.perPage"
          :total-rows="tableConfig.totalRows"
          class="mb-0"
        />
      </b-col>
      <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
        <HeroTablePerPage
          v-model="tableConfig.perPage"
          :options="tableConfig.perPageOptions"
          class="mb-0"
        >
          <template #label>
            <div />
          </template>
        </HeroTablePerPage>
        <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
      </b-col>
    </b-row>

    <hr>
    <HeroButtonAction
      type="button"
      variant="outline-secondary"
      class="w-auto mt-0 ml-auto"
      :disabled="isLoading"
      @click="$bvModal.hide('exclude-leads-modal')"
    >
      Close
    </HeroButtonAction>
  </b-modal>
</template>
<script>

import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import moment from 'moment'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'
import { BSpinner } from 'bootstrap-vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'

export default {
  components: {
    HeroVueSelect,
    BSpinner,
    HeroTableStatus,
    HeroTablePerPage,
    HeroTablePagination,
    HeroButtonAction,
  },
  props: {
    excludeItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      popperInstance: null,
      reasonOptions: [
        {
          text: 'None',
          value: '',
        },
        {
          text: 'Client has already churned but is still using Heroleads\' ad forms.',
          value: 'Client has already churned but is still using Heroleads\' ad forms.',
        },
        {
          text: 'Client don\'t use HeroVisionX or don\'t have notification.',
          value: 'Client don\'t use HeroVisionX or don\'t have notification.',
        },
      ],
      items: [],
      tableConfig: {
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'NAME',
            key: 'name',
            thStyle: { minWidth: '200px' },
          },
          {
            label: 'EMAIL',
            key: 'email',
          },
          {
            label: 'PHONE',
            key: 'phone',
          },
          {
            label: 'MESSAGE',
            key: 'message',
          },
          {
            label: 'STATUS',
            key: 'status',
          },
          {
            label: 'Reason',
            key: 'currentReason',
            thStyle: {
              minWidth: '596px',
              width: '596px',
            },

          },
          {
            label: 'Action',
            key: 'action',
          },
        ],
      },
    }
  },
  computed: {
    isLoading() {
      return this.items.some(item => item.isLoading)
    },
  },
  mounted() {
    const scriptSrc = 'https://unpkg.com/@popperjs/core@2'

    if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
      const script = document.createElement('script')
      script.src = scriptSrc

      script.onload = () => {
      // eslint-disable-next-line no-undef
        this.popperInstance = Popper
      }

      document.body.appendChild(script)
    }
  },
  methods: {
    async updateReason({ leadId, currentReason, excludedReason }) {
      const currentItemIndex = this.items.findIndex(item => item.leadId === leadId)

      try {
        this.items[currentItemIndex].isLoading = true

        const baseUrl = `${process.env.VUE_APP_LEAD_SERVICE_API}`
        const url = excludedReason ? '/lead/excluded-reason/unassigned' : '/lead/excluded-reason/assign'
        const response = await axiosInstance.post(`${baseUrl}/${url}`, {
          leadId,
          ...excludedReason === '' && { reason: currentReason },
        }, { withCredentials: false })

        const { status, message, data } = response.data

        this.items[currentItemIndex].message = message
        this.items[currentItemIndex].status = status === 'success'

        if (this.items[currentItemIndex].status) {
          this.items[currentItemIndex].excludedReason = data.excluded_reason || ''
          this.items[currentItemIndex].currentReason = ''
        }

        this.$emit('update-success')
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.items[currentItemIndex].isLoading = false
      }
    },

    onShow() {
      this.items = this.excludeItems.map(excludeItem => ({
        ...excludeItem,
        message: '',
        status: false,
        currentReason: excludeItem.excludedReason,
        isLoading: false,
      }))
      this.tableConfig.totalRows = this.items.length
    },

    async setPopper(elementId) {
      const currentSelectElement = document.getElementById(elementId)
      const dropdownToggleElement = currentSelectElement.querySelector('.vs__dropdown-toggle')

      await this.$nextTick()

      const dropdownMenuElement = currentSelectElement.querySelector('.vs__dropdown-menu')

      const offsetY = -40

      const popper = this.popperInstance.createPopper(dropdownToggleElement, dropdownMenuElement, {
        placement: 'auto',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, offsetY],
            },
          },
          {
            name: 'flip',
            options: {
              allowedAutoPlacements: ['top', 'bottom'],
            },
          },
        ],
      })

      return () => popper.destroy()
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .modal-backdrop {
  width: 100%;
  height: 100%;
}
.check-icon {
  polyline {
    stroke: #28A745;
  }
}

.x-icon {
  line {
    stroke: #DC3545;
  }
}
</style>
